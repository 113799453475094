import Ellipsis from '@components/Ellipsis';

interface IProps {
  notificationId: number;
  notificationDetail?: string;
  text: string;
  threadTitle?: string;
}

const NotificationMessage = ({
  notificationId,
  notificationDetail,
  text,
  threadTitle,
}: IProps) => {
  return (
    <div className="overflow-hidden font-[400] font-medium">
      <span className="font-bold">{notificationDetail}</span>
      {` ${text} `}
      {threadTitle && (
        <Ellipsis
          title={threadTitle}
          id={`description ${notificationId}`}
          hideIcon
          className="font-bold"
        />
      )}
    </div>
  );
};

export default NotificationMessage;
