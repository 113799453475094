import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import AutoResizableInput from '@components/inputs/AutoResizableInput';

import { INumericFormInputProps } from './types';
import { countCommasDotsDashes } from './utils';

const NumericFormInput = ({
  id,
  maxLength,
  numberInputProps = {},
  isBlockOnlyDot,
  ...props
}: INumericFormInputProps & {
  isBlockOnlyDot?: boolean;
}) => {
  // Dynamic max length is used to increase the input length when a comma or dot is added. Not handled by react-number-format
  const [dynamicMaxLength, setDynamicMaxLength] = useState(maxLength);

  const { control } = useFormContext();

  const {
    onNumberChange,
    maxDecimalCount,
    maxIntegerLength,
    allowLeadingZeros,
    thousandSeparator,
    decimalSeparator,
  } = numberInputProps;

  const isIntegerLimitObeyed = (num?: number) => {
    if (!maxIntegerLength || !num) return true;

    const integerPart = Math.floor(num);
    const integerPoints = integerPart.toString().length;

    return integerPoints <= maxIntegerLength;
  };

  const readjustMaxLength = (value: string) => {
    if (!maxLength) return;

    const specialCharacterCount = countCommasDotsDashes(value);

    if (specialCharacterCount) {
      setDynamicMaxLength(maxLength + specialCharacterCount);
    }
  };

  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) => {
        // When left as is react will throw an error about uncontrolled input
        const parsedValue = [null, undefined].includes(field.value)
          ? ''
          : field.value;

        return (
          <NumericFormat
            {...props}
            controlField={{
              ...field,
              value: parsedValue,
            }}
            value={parsedValue}
            id={id}
            type="text"
            customInput={AutoResizableInput}
            thousandSeparator={thousandSeparator || false}
            maxLength={dynamicMaxLength}
            valueIsNumericString
            allowNegative={false}
            decimalScale={maxDecimalCount || 0}
            allowLeadingZeros={!!allowLeadingZeros}
            decimalSeparator={decimalSeparator || undefined}
            isAllowed={(values) => {
              if (isBlockOnlyDot) {
                if (!values.value) return true;
                if (isBlockOnlyDot && values.value === '.') return false;
              }

              return isIntegerLimitObeyed(values.floatValue);
            }}
            onValueChange={(values) => {
              if (isBlockOnlyDot) {
                if (values.value === '.') {
                  return onNumberChange?.({
                    ...values,
                    value: '',
                    floatValue: undefined,
                  });
                }
                if (!values.value) {
                  readjustMaxLength('');
                  return onNumberChange?.({
                    ...values,
                    floatValue: undefined,
                  });
                }
              }
              readjustMaxLength(values.formattedValue);

              return onNumberChange?.(values);
            }}
          />
        );
      }}
    />
  );
};

export default NumericFormInput;
