import {
  GetPurchasesListResponse,
  GetRefundsListResponse,
  GetUserReferralCodeResponse,
  GetUserReferralsListResponse,
  IDirectDeposit,
  IGenericResponse,
  IGetPurchaseHistoryItemResponse,
  IGetPurchaseResponse,
  IGetTokenBaseRequestHistoryResponse,
  IGetTokenBaseStatisticResponse,
  INowPayment,
  INowPaymentDetails,
  IReceiptResponse,
  PaginatedResponseWrapper,
  ProductApiKeyResponse,
  ResponseWrapper,
  UserPurchaseHistoryParams,
  UserTokenBaseStatisticParams,
} from '@interfaces';
import { IFetchParams } from '@interfaces/queryParams';
import { UserGetPublisherProfileResponse } from '@interfaces/user/profile/publisherProfile';

import {
  withAuthenticatedClientRequest,
  withClientRequest,
} from '@api/requestBuilder/client/withClientRequest';
import {
  appendParamsToUrl,
  formatDateParams,
  generateRefundStatus,
} from '@api/requestBuilder/helpers';
import {
  withAuthenticatedServerRequest,
  withServerRequest,
} from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';
import { getSort } from '@api/utils';

import config from '@shared/config';
import { ESortDirection } from '@shared/enums/queries';
import { RefundRequestStatus } from '@shared/enums/refunds';

import { DEFAULT_REFUND_STATUS } from './constants';

const getPurchasedApps =
  (request: ICallableRequestBuilder<GetPurchasesListResponse>) =>
    async (params?: IFetchParams, timeZone?: string) => {
      const formattedParams = formatDateParams(params, timeZone);
      const url = appendParamsToUrl(
        `${config.userApiUrl}/api/v1/user/profile/purchases/list`,
        { ...formattedParams }
      );

      return request.call(url);
    };

const getRefunds =
  (request: ICallableRequestBuilder<GetRefundsListResponse>) =>
    async (params?: IFetchParams) => {
      const url = appendParamsToUrl(
        `${config.userApiUrl}/api/v1/user/refund-requests`,
        {
          ...params,
          ...getSort(params),
          ...generateRefundStatus(
            params?.['filter.status'] as string,
            DEFAULT_REFUND_STATUS as RefundRequestStatus[]
          ),
        }
      );

      return request.call(url);
    };

const getReferrals =
  (request: ICallableRequestBuilder<GetUserReferralsListResponse>) =>
    async (params?: IFetchParams) => {
      const url = appendParamsToUrl(
        `${config.userApiUrl}/api/v1/user/referrals`,
        {
          ...params,
          ...getSort(params),
        }
      );

      return request.call(url);
    };

const getReferralCode =
  (request: ICallableRequestBuilder<GetUserReferralCodeResponse>) =>
    async () => {
      const url = appendParamsToUrl(`${config.userApiUrl}/api/v1/user/referrals/code`);
      return request.call(url);
    };

const updateProfile =
  (request: ICallableRequestBuilder<IGenericResponse<object>>) =>
    async (avatar: string) => {
      return request.call(`${config.userApiUrl}/api/v1/user/profile`, (init) => ({
        ...init,
        method: 'PUT',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          avatar,
        }),
      }));
    };

const getProfilePurchaseInfo =
  (request: ICallableRequestBuilder<IGetPurchaseResponse>) => async () => {
    return request.call(`${config.userApiUrl}/api/v1/user/profile/purchase`);
  };

const getPurchaseHistory =
  (request: ICallableRequestBuilder<IGetPurchaseHistoryItemResponse>) =>
    async ({
      purchaseItemId,
      sortDirection = ESortDirection.DESC,
    }: UserPurchaseHistoryParams) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/profile/purchases/history/${purchaseItemId}/${sortDirection}`
      );
    };

const getTokenBaseStatistic =
  (request: ICallableRequestBuilder<IGetTokenBaseStatisticResponse>) =>
    async ({
      productId,
    }: UserTokenBaseStatisticParams) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/token-based/${productId}/statistic`
      );
    };

const getTokenBaseRequest =
  (request: ICallableRequestBuilder<IGetTokenBaseRequestHistoryResponse>) =>
    async ({
      productId,
    }: UserTokenBaseStatisticParams) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/token-based/${productId}/requests`
      );
    };
const getPurchaseReceipt =
  (request: ICallableRequestBuilder<IReceiptResponse>) =>
    async (purchaseId: string) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/profile/purchases/receipt/onetime/${purchaseId}`
      );
    };

const getSubscriptionReceipt =
  (request: ICallableRequestBuilder<IReceiptResponse>) =>
    async (purchaseId: string) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/profile/purchases/receipt/subscription_monthly/${purchaseId}`
      );
    };

const cancelSubscription =
  (request: ICallableRequestBuilder<ResponseWrapper>) =>
    async (subscriptionId: string, productId: string) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/products/subscription/${subscriptionId}/cancel`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            ...init.headers,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            productId,
          }),
        })
      );
    };

const getPublisherUserProfile =
  (request: ICallableRequestBuilder<UserGetPublisherProfileResponse>) =>
    async (id: string) => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/publisher/${id}/profile`
      );
    };

const getProductApiKey =
  (request: ICallableRequestBuilder<ProductApiKeyResponse>) => async () => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/profile/product-api-key`
    );
  };

const generateProductApiKey =
  (request: ICallableRequestBuilder<ProductApiKeyResponse>) => async () => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/profile/product-api-key/generate`,
      (init) => ({
        ...init,
        method: 'POST',
        headers: {
          ...init.headers,
          'Content-Type': 'application/json',
        },
      })
    );
  };

const getPayments =
  (request: ICallableRequestBuilder<PaginatedResponseWrapper<INowPayment[]>>) =>
    async (params: IFetchParams, timeZone?: string) => {
      const formattedParams = formatDateParams(params, timeZone);
      const url = appendParamsToUrl(
        `${config.userApiUrl}/api/v1/user/now-payment/transactions`,
        { ...formattedParams }
      );

      return request.call(url);
    };

const getNowPaymentDetails =
  (
    request: ICallableRequestBuilder<
      PaginatedResponseWrapper<INowPaymentDetails>
    >
  ) =>
    async (id: number) => {
      const url = `${config.userApiUrl}/api/v1/user/now-payment/${id}`;

      return request.call(url);
    };

const getDeposits =
  (
    request: ICallableRequestBuilder<PaginatedResponseWrapper<IDirectDeposit[]>>
  ) =>
    async (params: IFetchParams, timeZone?: string) => {
      const formattedParams = formatDateParams(params, timeZone);

      const url = appendParamsToUrl(
        `${config.userApiUrl}/api/v1/user/deposit-transactions`,
        { ...formattedParams }
      );

      return request.call(url);
    };

export const profileApi = {
  client: {
    getPurchasedApps: withAuthenticatedClientRequest(getPurchasedApps),
    getRefunds: withAuthenticatedClientRequest(getRefunds),
    getProfilePurchaseInfo: withAuthenticatedClientRequest(
      getProfilePurchaseInfo
    ),
    getPublisherUserProfile: withClientRequest(getPublisherUserProfile),
    getPurchaseHistory: withAuthenticatedClientRequest(getPurchaseHistory),
    getTokenBaseStatistic: withAuthenticatedClientRequest(getTokenBaseStatistic),
    getTokenBaseRequest: withAuthenticatedClientRequest(getTokenBaseRequest),
    getPurchaseReceipt: withAuthenticatedClientRequest(getPurchaseReceipt),
    getSubscriptionReceipt: withAuthenticatedClientRequest(
      getSubscriptionReceipt
    ),
    updateProfile: withAuthenticatedClientRequest(updateProfile),
    cancelSubscription: withAuthenticatedClientRequest(cancelSubscription),
    getProductApiKey: withAuthenticatedClientRequest(getProductApiKey),
    generateProductApiKey: withAuthenticatedClientRequest(
      generateProductApiKey
    ),
    getNowPayments: withAuthenticatedClientRequest(getPayments),
    getNowPaymentDetails: withAuthenticatedClientRequest(getNowPaymentDetails),
    getDirectDeposits: withAuthenticatedClientRequest(getDeposits),
    getReferrals: withAuthenticatedClientRequest(getReferrals),
    getReferralCode: withAuthenticatedClientRequest(getReferralCode),
  },
  server: {
    getPurchasedApps: withAuthenticatedServerRequest(getPurchasedApps),
    getRefunds: withAuthenticatedServerRequest(getRefunds),
    getProfilePurchaseInfo: withAuthenticatedServerRequest(
      getProfilePurchaseInfo
    ),
    getPublisherUserProfile: withServerRequest(getPublisherUserProfile),
    getProductApiKey: withAuthenticatedServerRequest(getProductApiKey),
    getNowPayments: withAuthenticatedServerRequest(getPayments),
    getNowPaymentDetails: withAuthenticatedServerRequest(getNowPaymentDetails),
    getDirectDeposits: withAuthenticatedClientRequest(getDeposits),
    getReferrals: withAuthenticatedServerRequest(getReferrals),
    getReferralCode: withAuthenticatedServerRequest(getReferralCode),
  },
};
